<template>
  <div v-if="currentSession">
    <!-- Mobile layout -->
    <responsive-layout
      v-if="$vuetify.breakpoint.mobile"
      poster="https://vod.eventcdn.net/pandora/smc2021/Information_Bg.png"
    >
      <v-sheet
        style="min-height: 100vh"
        class="d-flex flex-column justify-center transparent"
        dark
      >
        <div class="main py-8 flex-fill">
          <v-container fluid>
            <!-- 'Breadcrumb' navigation at the top -->
            <v-row justify="start" align="start" class="mt-5 lobby-router-row">
              <v-col
                cols="12"
                class="pl-0 d-flex align-start justify-start flex-column ml-1"
              >
                <!--<v-btn
                  class="ma-2 white--text lobby-back-button rounded-0 mr-0 mb-0"
                  color="black"
                >
                  <router-link class="white--text" to="/lobby">
                    <v-icon left dark color="pink" class="back-arrow-theatre">
                      fas fa-chevron-left
                    </v-icon>
                    <span class="back-arrow-lobby-link">To lobby</span>
                  </router-link>
                </v-btn>-->
                <AppBackButton class="ml-2 mt-2" :to="{ name: 'Lobby' }"
                  >TO LOBBY</AppBackButton
                >
                <div class="route-name-background-container-information ml-2">
                  <span class="theatre-route-name">{{ $route.meta.name }}</span>
                </div>
              </v-col>
            </v-row>

            <!-- Downloads section -->
            <v-row
              justify="start"
              align="start"
              class="mt-5 sessions-agenda-lobby-row"
            >
              <v-col cols="12">
                <div
                  class="pl-1 py-3 black text-left white--text"
                  style="font-size: 1.2rem"
                >
                  <h3 class="pa-4">DOWNLOADS</h3>
                </div>
                <div class="py-6 d-flex white pl-2 pr-4">
                  <v-col cols="12" class="pl-3 pr-3">
                    <v-col
                      cols="12"
                      v-for="(download, idx) in downloads"
                      :key="idx"
                      class="
                        agenda-container
                        mt-4
                        mb-4
                        d-flex
                        align-center
                        pt-0
                        pb-0
                        pr-0
                      "
                    >
                      <v-col cols="6" class="d-flex pt-0 pb-0">
                        <v-icon left dark color="black" class="mr-3">
                          fas fa-file-pdf
                        </v-icon>
                        <p class="downloads-title mb-0">{{ download.name }}</p>
                      </v-col>
                      <v-col
                        cols="6"
                        class="d-flex align-center justify-end pr-0 pt-0 pb-0"
                      >
                        <p
                          class="downloads-points mb-0"
                          v-if="event.state !== 'ONDEMAND'"
                        >
                          {{ download.points }} pts.
                        </p>
                        <DownloadTracker
                          class="black py-2 px-4 ml-2"
                          :href="download.href"
                        >
                          <v-icon dark color="pink"> fas fa-download </v-icon>
                        </DownloadTracker>
                      </v-col>
                    </v-col>
                  </v-col>
                </div>

                <!-- Sessions in agenda section -->

                <v-col cols="12" class="pb-0 pr-0 pl-0 mt-4">
                  <div
                    class="pl-1 py-3 black text-left white--text"
                    style="font-size: 1.2rem"
                  >
                    <h3 class="pa-4">SESSIONS IN AGENDA</h3>
                  </div>
                  <div
                    class="
                      py-6
                      d-flex
                      white
                      pl-2
                      pr-2
                      d-flex
                      white
                      flex-row flex-wrap
                    "
                  >
                    <v-col cols="8" sm="4" class="mt-4 mb-4 d-flex">
                      <v-col cols="6" class="pink pink-date-pill d-flex pa-0">
                        <p class="mb-0">TUESDAY</p>
                      </v-col>
                      <v-col cols="6" class="lighter-grey grey-date-pill pa-0">
                        <span class="mb-0 date-span"> 14 </span>
                        <span class="ml-1">SEP</span>
                      </v-col>
                    </v-col>
                    <v-col cols="12" class="d-flex flex-row flex-wrap pl-0">
                      <v-col
                        cols="12"
                        v-for="session in sessions.filter(
                          (session) => session.day === 1
                        )"
                        :key="session.id"
                        class="agenda-container d-flex white pt-0 pb-0 pl-0"
                      >
                        <v-col cols="12" class="text-left">
                          <v-col cols="12" class="pb-0 pt-0">
                            <p class="information-session-date mb-0">
                              {{ session.details.time || "TIME???" }}
                            </p>
                          </v-col>
                          <v-col cols="12" class="d-flex pa-0">
                            <v-col cols="8">
                              <h2 class="information-session-title">
                                {{ session.name }}
                              </h2>
                            </v-col>
                            <v-col cols="4" class="pr-0 ml-1">
                              <p
                                class="information-session-points"
                                v-if="event.state !== 'ONDEMAND'"
                              >
                                {{ session.details.points || "POINTS???" }} pts
                              </p>
                            </v-col>
                          </v-col>
                          <v-divider class="lighter-grey-base"></v-divider>
                        </v-col>
                      </v-col>
                    </v-col>
                  </div>
                </v-col>

                <v-col cols="12" class="pt-0 pl-0 pr-0 pb-0">
                  <v-divider class="pink"></v-divider>
                  <div class="py-6 d-flex white pl-2 pr-2 flex-row flex-wrap">
                    <v-col cols="8" sm="4" class="mt-4 mb-4 d-flex">
                      <v-col cols="6" class="pink pink-date-pill d-flex pa-0">
                        <p class="mb-0">WEDNESDAY</p>
                      </v-col>
                      <v-col cols="6" class="lighter-grey grey-date-pill pa-0">
                        <span class="mb-0 date-span"> 15 </span>
                        <span class="ml-1">SEP</span>
                      </v-col>
                    </v-col>
                    <v-col cols="12" class="d-flex flex-row flex-wrap pl-0">
                      <v-col
                        cols="12"
                        v-for="session in sessions.filter(
                          (session) => session.day === 2
                        )"
                        :key="session.id"
                        class="agenda-container d-flex white pt-0 pb-0 pl-0"
                      >
                        <v-col cols="12" class="text-left">
                          <v-col cols="12" class="pb-0 pt-0">
                            <p class="information-session-date mb-0">
                              {{ session.details.time || "TIME???" }}
                            </p>
                          </v-col>
                          <v-col cols="12" class="d-flex pa-0">
                            <v-col cols="8">
                              <h2 class="information-session-title">
                                {{ session.name }}
                              </h2>
                            </v-col>
                            <v-col cols="4" class="pr-0 ml-1">
                              <p
                                class="information-session-points"
                                v-if="event.state !== 'ONDEMAND'"
                              >
                                {{ session.details.points || "POINTS???" }} pts
                              </p>
                            </v-col>
                          </v-col>
                          <v-divider class="lighter-grey-base"></v-divider>
                        </v-col>
                      </v-col>
                    </v-col>
                  </div>
                </v-col>

                <v-col cols="12" class="pt-0 pr-0 pl-0">
                  <v-divider class="pink"></v-divider>
                  <div class="py-6 d-flex white pl-2 pr-2 flex-row flex-wrap">
                    <v-col cols="8" sm="4" class="mt-4 mb-4 d-flex">
                      <v-col cols="6" class="pink pink-date-pill d-flex pa-0">
                        <p class="mb-0">THURSDAY</p>
                      </v-col>
                      <v-col cols="6" class="lighter-grey grey-date-pill pa-0">
                        <span class="mb-0 date-span"> 16 </span>
                        <span class="ml-1">SEP</span>
                      </v-col>
                    </v-col>
                    <v-col cols="12" class="d-flex flex-row flex-wrap pl-0">
                      <v-col
                        cols="12"
                        v-for="session in sessions.filter(
                          (session) => session.day === 3
                        )"
                        :key="session.id"
                        class="agenda-container d-flex white pt-0 pb-0 pl-0"
                      >
                        <v-col cols="12" class="text-left">
                          <v-col cols="12" class="pb-0 pt-0">
                            <p class="information-session-date mb-0">
                              {{ session.details.time || "TIME???" }}
                            </p>
                          </v-col>
                          <v-col cols="12" class="d-flex pa-0">
                            <v-col cols="8">
                              <h2 class="information-session-title">
                                {{ session.name }}
                              </h2>
                            </v-col>
                            <v-col cols="4" class="pr-0 ml-1">
                              <p
                                class="information-session-points"
                                v-if="event.state !== 'ONDEMAND'"
                              >
                                {{ session.details.points || "POINTS???" }} pts
                              </p>
                            </v-col>
                          </v-col>
                          <v-divider class="lighter-grey-base"></v-divider>
                        </v-col>
                      </v-col>
                    </v-col>
                  </div>
                </v-col>
              </v-col>

              <!-- Speaker bios section -->

              <v-col cols="12">
                <div
                  class="pl-1 py-3 black text-left white--text"
                  style="font-size: 1.2rem"
                >
                  <h3 class="pa-4">SPEAKER BIOS</h3>
                </div>
                <div class="py-6 d-flex white pr-4">
                  <v-col cols="12" class="pl-3 pr-3">
                    <v-col
                      cols="12"
                      v-for="(speaker, idx) in speakers"
                      :key="idx"
                      class="agenda-container d-flex white pl-2 pt-1"
                    >
                      <v-col cols="12" sm="6" class="pl-0">
                        <v-img
                          :src="speaker.image"
                          style="max-width: 100%"
                        ></v-img>
                      </v-col>
                      <v-col cols="12" sm="6" class="text-left">
                        <p class="mt-2 information-speaker-name">
                          {{ speaker.name }}
                        </p>
                        <p class="mt-2 information-speaker-description">
                          {{ speaker.description }}
                        </p>
                        <v-divider class="lighter-grey-base"></v-divider>
                      </v-col>
                    </v-col>
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>
    </responsive-layout>

    <!-- 16:9 layout for desktop devices -->
    <aspect-ratio-layout
      v-else
      poster="https://vod.eventcdn.net/pandora/smc2021/backgrounds/Information_loop.jpg"
      background-video-initial="https://vod.eventcdn.net/pandora/smc2021/BackgroundVideo/Information.mp4"
      background-video-loop="https://vod.eventcdn.net/pandora/smc2021/BackgroundVideo/loop/Information_loop.mp4"
      #default="{ replayBackgroundVideo, isLooping }"
    >
      <v-sheet class="d-flex flex-column transparent" light>
        <div class="main">
          <template v-if="isLooping">
            <div style="position: fixed; right: 353px; top: 49%">
              <div style="position: relative" class="d-flex align-center">
                <v-btn
                  color="black"
                  class="play-btn pr-7"
                  style="position: unset"
                >
                  <span class="white--text play-btn-text">WELCOME</span>
                </v-btn>

                <v-btn
                  fab
                  class="rounded-circle ml-n4"
                  color="white"
                  @click="replayBackgroundVideo"
                  style="z-index: 16"
                >
                  <v-icon dark color="pink"> fas fa-play </v-icon>
                </v-btn>
              </div>
            </div>
          </template>
          <v-container fluid>
            <!-- 'Breadcrumb' navigation at the top -->
            <v-row justify="start" align="start" class="mt-5 lobby-router-row">
              <v-col
                cols="8"
                class="pl-0 d-flex align-start justify-start ml-10"
              >
                <!--<v-btn
                  class="ma-2 white--text lobby-back-button rounded-0 mr-0"
                  color="black"
                >
                  <router-link class="white--text" to="/lobby">
                    <v-icon left dark color="pink" class="back-arrow-theatre">
                      fas fa-chevron-left
                    </v-icon>
                    <span class="back-arrow-lobby-link">To lobby</span>
                  </router-link>
                </v-btn>-->
                <AppBackButton class="ml-2 mt-2" :to="{ name: 'Lobby' }"
                  >TO LOBBY</AppBackButton
                >
                <div
                  class="
                    route-name-background-container-meet-your-colleagues
                    mt-2
                  "
                >
                  <span class="theatre-route-name">{{ $route.meta.name }}</span>
                </div>
              </v-col>
            </v-row>

            <!-- Downloads section -->
            <v-row
              justify="start"
              align="start"
              class="mt-5 sessions-agenda-lobby-row"
            >
              <v-col cols="4">
                <div
                  class="pl-6 py-3 black text-left white--text"
                  style="font-size: 1.2rem"
                >
                  <h3 class="pa-4">DOWNLOADS</h3>
                </div>
                <div class="py-6 d-flex white pl-2 pr-4">
                  <v-col cols="12" class="pl-8 pr-8">
                    <v-col
                      cols="12"
                      v-for="(download, idx) in downloads"
                      :key="idx"
                      class="
                        agenda-container
                        mt-4
                        mb-4
                        d-flex
                        align-center
                        pt-0
                        pb-0
                        pr-0
                      "
                    >
                      <v-col cols="6" class="d-flex pt-0 pb-0">
                        <v-icon left dark color="black" class="mr-3">
                          fas fa-file-pdf
                        </v-icon>
                        <p class="downloads-title mb-0">{{ download.name }}</p>
                      </v-col>
                      <v-col
                        cols="6"
                        class="d-flex align-center justify-end pr-0 pt-0 pb-0"
                      >
                        <p
                          class="downloads-points mb-0"
                          v-if="event.state !== 'ONDEMAND'"
                        >
                          {{ download.points }} pts.
                        </p>
                        <DownloadTracker
                          class="black py-2 px-4 ml-2"
                          :href="download.href"
                        >
                          <v-icon dark color="pink"> fas fa-download </v-icon>
                        </DownloadTracker>
                      </v-col>
                    </v-col>
                  </v-col>
                </div>

                <!-- Sessions in agenda section -->

                <v-col cols="12" class="pb-0 pr-0 pl-0 mt-4">
                  <div
                    class="pl-6 py-3 black text-left white--text"
                    style="font-size: 1.2rem"
                  >
                    <h3 class="pa-4">SESSIONS IN AGENDA</h3>
                  </div>
                  <div class="py-6 d-flex white pl-6 pr-4">
                    <v-col cols="4" class="mt-4 mb-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="147"
                        height="173"
                        viewBox="0 0 147 173"
                        class="date-svg"
                      >
                        <defs>
                          <filter
                            id="a"
                            x="0"
                            y="1"
                            width="147"
                            height="172"
                            filterUnits="userSpaceOnUse"
                          >
                            <feOffset dy="2" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="2" result="b" />
                            <feFlood flood-opacity="0.102" />
                            <feComposite operator="in" in2="b" />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g transform="translate(6)">
                          <g class="g" transform="matrix(1, 0, 0, 1, -6, 0)">
                            <rect
                              class="a"
                              width="135"
                              height="160"
                              rx="10"
                              transform="translate(6 5)"
                            />
                          </g>
                          <path
                            class="b"
                            d="M10,0H125a10,10,0,0,1,10,10V53H0V10A10,10,0,0,1,10,0Z"
                            transform="translate(0 5)"
                          />
                          <circle
                            class="c"
                            cx="7"
                            cy="7"
                            r="7"
                            transform="translate(24 12)"
                          />
                          <circle
                            class="c"
                            cx="7"
                            cy="7"
                            r="7"
                            transform="translate(98 12)"
                          />
                          <rect
                            class="d"
                            width="10"
                            height="24"
                            rx="5"
                            transform="translate(26)"
                          />
                          <rect
                            class="d"
                            width="10"
                            height="24"
                            rx="5"
                            transform="translate(100)"
                          />
                          <text class="e" transform="translate(27 47)">
                            <tspan y="80">14</tspan>
                          </text>
                          <text class="f" transform="translate(68 47)">
                            <tspan style="text-anchor: middle" y="0">
                              TUESDAY
                            </tspan>
                          </text>
                          <text class="f" transform="translate(68 150)">
                            <tspan x="-14" y="0">SEP</tspan>
                          </text>
                        </g>
                      </svg>
                    </v-col>
                    <v-col cols="8">
                      <v-col
                        cols="12"
                        v-for="session in sessions.filter(
                          (session) => session.day === 1
                        )"
                        :key="session.id"
                        class="agenda-container d-flex white pt-0 pb-0"
                      >
                        <v-col cols="12" class="text-left">
                          <v-col cols="12" class="pb-0 pt-0">
                            <p class="information-session-date mb-0">
                              {{ session.details.time || "TIME???" }}
                            </p>
                          </v-col>
                          <v-col cols="12" class="d-flex pa-0">
                            <v-col cols="8">
                              <h2 class="information-session-title">
                                {{ session.name }}
                              </h2>
                            </v-col>
                            <v-col cols="4" class="pr-0 ml-1">
                              <p
                                class="information-session-points"
                                v-if="event.state !== 'ONDEMAND'"
                              >
                                {{ session.details.points || "POINTS???" }} pts
                              </p>
                            </v-col>
                          </v-col>
                          <v-divider class="lighter-grey-base"></v-divider>
                        </v-col>
                      </v-col>
                    </v-col>
                  </div>
                </v-col>

                <v-col cols="12" class="pt-0 pl-0 pr-0 pb-0">
                  <v-divider class="pink"></v-divider>
                  <div class="py-6 d-flex white pl-6 pr-4">
                    <v-col cols="4" class="mt-4 mb-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="147"
                        height="173"
                        viewBox="0 0 147 173"
                        class="date-svg"
                      >
                        <defs>
                          <filter
                            id="a"
                            x="0"
                            y="1"
                            width="147"
                            height="172"
                            filterUnits="userSpaceOnUse"
                          >
                            <feOffset dy="2" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="2" result="b" />
                            <feFlood flood-opacity="0.102" />
                            <feComposite operator="in" in2="b" />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g transform="translate(6)">
                          <g class="g" transform="matrix(1, 0, 0, 1, -6, 0)">
                            <rect
                              class="a"
                              width="135"
                              height="160"
                              rx="10"
                              transform="translate(6 5)"
                            />
                          </g>
                          <path
                            class="b"
                            d="M10,0H125a10,10,0,0,1,10,10V53H0V10A10,10,0,0,1,10,0Z"
                            transform="translate(0 5)"
                          />
                          <circle
                            class="c"
                            cx="7"
                            cy="7"
                            r="7"
                            transform="translate(24 12)"
                          />
                          <circle
                            class="c"
                            cx="7"
                            cy="7"
                            r="7"
                            transform="translate(98 12)"
                          />
                          <rect
                            class="d"
                            width="10"
                            height="24"
                            rx="5"
                            transform="translate(26)"
                          />
                          <rect
                            class="d"
                            width="10"
                            height="24"
                            rx="5"
                            transform="translate(100)"
                          />
                          <text class="e" transform="translate(27 47)">
                            <tspan y="80">15</tspan>
                          </text>
                          <text
                            class="f text-center"
                            transform="translate(68 47)"
                          >
                            <tspan style="text-anchor: middle" y="0">
                              WEDNESDAY
                            </tspan>
                          </text>
                          <text class="f" transform="translate(68 150)">
                            <tspan x="-14" y="0">SEP</tspan>
                          </text>
                        </g>
                      </svg>
                    </v-col>
                    <v-col cols="8">
                      <v-col
                        cols="12"
                        v-for="session in sessions.filter(
                          (session) => session.day === 2
                        )"
                        :key="session.id"
                        class="agenda-container d-flex white pt-0 pb-0"
                      >
                        <v-col cols="12" class="text-left">
                          <v-col cols="12" class="pb-0 pt-0">
                            <p class="information-session-date mb-0">
                              {{ session.details.time || "TIME???" }}
                            </p>
                          </v-col>
                          <v-col cols="12" class="d-flex pa-0">
                            <v-col cols="8">
                              <h2 class="information-session-title">
                                {{ session.name }}
                              </h2>
                            </v-col>
                            <v-col cols="4" class="pr-0 ml-1">
                              <p
                                class="information-session-points"
                                v-if="event.state !== 'ONDEMAND'"
                              >
                                {{ session.details.points || "POINTS???" }} pts
                              </p>
                            </v-col>
                          </v-col>
                          <v-divider class="lighter-grey-base"></v-divider>
                        </v-col>
                      </v-col>
                    </v-col>
                  </div>
                </v-col>

                <v-col cols="12" class="pt-0 pr-0 pl-0">
                  <v-divider class="pink"></v-divider>
                  <div class="py-6 d-flex white pl-6 pr-4">
                    <v-col cols="4" class="mt-4 mb-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="147"
                        height="173"
                        viewBox="0 0 147 173"
                        class="date-svg"
                      >
                        <defs>
                          <filter
                            id="a"
                            x="0"
                            y="1"
                            width="147"
                            height="172"
                            filterUnits="userSpaceOnUse"
                          >
                            <feOffset dy="2" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="2" result="b" />
                            <feFlood flood-opacity="0.102" />
                            <feComposite operator="in" in2="b" />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g transform="translate(6)">
                          <g class="g" transform="matrix(1, 0, 0, 1, -6, 0)">
                            <rect
                              class="a"
                              width="135"
                              height="160"
                              rx="10"
                              transform="translate(6 5)"
                            />
                          </g>
                          <path
                            class="b"
                            d="M10,0H125a10,10,0,0,1,10,10V53H0V10A10,10,0,0,1,10,0Z"
                            transform="translate(0 5)"
                          />
                          <circle
                            class="c"
                            cx="7"
                            cy="7"
                            r="7"
                            transform="translate(24 12)"
                          />
                          <circle
                            class="c"
                            cx="7"
                            cy="7"
                            r="7"
                            transform="translate(98 12)"
                          />
                          <rect
                            class="d"
                            width="10"
                            height="24"
                            rx="5"
                            transform="translate(26)"
                          />
                          <rect
                            class="d"
                            width="10"
                            height="24"
                            rx="5"
                            transform="translate(100)"
                          />
                          <text class="e" transform="translate(27 47)">
                            <tspan y="80">16</tspan>
                          </text>
                          <text class="f" transform="translate(68 47)">
                            <tspan style="text-anchor: middle" y="0">
                              THURSDAY
                            </tspan>
                          </text>
                          <text class="f" transform="translate(68 150)">
                            <tspan x="-14" y="0">SEP</tspan>
                          </text>
                        </g>
                      </svg>
                    </v-col>
                    <v-col cols="8">
                      <v-col
                        cols="12"
                        v-for="session in sessions.filter(
                          (session) => session.day === 3
                        )"
                        :key="session.id"
                        class="agenda-container d-flex white pt-0 pb-0"
                      >
                        <v-col cols="12" class="text-left">
                          <v-col cols="12" class="pb-0 pt-0">
                            <p class="information-session-date mb-0">
                              {{ session.details.time || "TIME???" }}
                            </p>
                          </v-col>
                          <v-col cols="12" class="d-flex pa-0">
                            <v-col cols="8">
                              <h2 class="information-session-title">
                                {{ session.name }}
                              </h2>
                            </v-col>
                            <v-col cols="4" class="pr-0 ml-1">
                              <p
                                class="information-session-points"
                                v-if="event.state !== 'ONDEMAND'"
                              >
                                {{ session.details.points || "POINTS???" }} pts
                              </p>
                            </v-col>
                          </v-col>
                          <v-divider class="lighter-grey-base"></v-divider>
                        </v-col>
                      </v-col>
                    </v-col>
                  </div>
                </v-col>
              </v-col>

              <!-- Speaker bios section -->

              <v-col cols="4">
                <div
                  class="pl-6 py-3 black text-left white--text"
                  style="font-size: 1.2rem"
                >
                  <h3 class="pa-4">WE DARE</h3>
                </div>
                <div class="py-6 d-flex white pr-4" style="margin-bottom: 30px">
                  <v-col cols="12" class="pl-8 pr-8 text-left">
                    We DREAM of Reaching for the stars with Moments<br />
                    We DELIVER sparkle and elegance with our Timeless
                    collection<br />
                    We DARE all of you to attract new consumers with our
                    innovative Pandora ME collection<br />
                    BUT, most importantly, we CARE about you – our store and
                    wholesale teams. <br />
                    We care about your continued resilience and strength. <br />
                    You're the ones that will make our 4Q brilliant with
                    talented and focused customer experiences. <br />
                    <br />
                    <div class="text-center">
                      <b
                        >THANK YOU AGAIN – FOR ALL YOU DO AND FOR MAKING PANDORA
                        4Q 2021 THE BEST YET!</b
                      >
                    </div>
                  </v-col>
                </div>

                <div
                  class="pl-6 py-3 black text-left white--text"
                  style="font-size: 1.2rem"
                >
                  <h3 class="pa-4">SPEAKER BIOS</h3>
                </div>
                <div class="py-6 d-flex white pr-4">
                  <v-col cols="12" class="pl-8 pr-8">
                    <v-col
                      cols="12"
                      v-for="(speaker, idx) in speakers"
                      :key="idx"
                      class="agenda-container d-flex white pl-2 pt-1"
                    >
                      <v-col cols="4" class="pl-0">
                        <v-img
                          :src="speaker.image"
                          style="max-width: 100%"
                        ></v-img>
                      </v-col>
                      <v-col cols="8" class="text-left">
                        <p class="mt-2 information-speaker-name">
                          {{ speaker.name }}
                        </p>
                        <p class="mt-2 information-speaker-description">
                          {{ speaker.description }}
                        </p>
                        <v-divider class="lighter-grey-base"></v-divider>
                      </v-col>
                    </v-col>
                  </v-col>
                </div>
              </v-col>
            </v-row>

            <!-- Hidden questions -->
            <AppHiddenQuestion
              top="8%"
              left="4%"
              :tracking-id="38"
            ></AppHiddenQuestion>
            <AppHiddenQuestion
              top="10%"
              right="9%"
              question="What is the top category that we saw in 2020 associate with a buy online, return in store?"
              :options="['Bracelets', 'Charms', 'Necklaces']"
              answer="Bracelets"
              :tracking-id="29"
            ></AppHiddenQuestion>

            <AppHiddenQuestion
              top="84%"
              right="24%"
              question="Why is personalization through engraving important to our brand?"
              :options="[
                'To make cool keychains',
                'To create new ways to connect to the shopper’s story',
                'To have bragging rights for stores in the mall who don\'t have an engraving machine',
              ]"
              answer="To create new ways to connect to the shopper’s story"
              :tracking-id="30"
            ></AppHiddenQuestion>
          </v-container>
        </div>
      </v-sheet>
    </aspect-ratio-layout>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import ResponsiveLayout from "../layouts/ResponsiveLayout.vue";
import AspectRatioLayout from "../layouts/16by9Layout.vue";
import theatreMockData from "../assets/data/theatre-mock-data.json";
import downloadsMockData from "../assets/data/information-downloads-mock-data.json";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ResponsiveLayout,
    AspectRatioLayout,
  },

  data() {
    return {
      dialog: false,
      dialogFirst: false,
      dialogSecretSecond: false,
      dialogSecretThird: false,
      column: null,
      row: null,
      overlay: false,
      mockData: theatreMockData,
      downloadsMockData: downloadsMockData,
    };
  },

  computed: {
    ...mapState(["sessions", "downloads", "event"]),
    ...mapGetters(["me", "currentSession", "speakers"]),
  },

  watch: {
    "$store.getters.isAuthenticated": async function (newValue, oldValue) {
      console.log("isAuthenticated change:", newValue, "oldValue:", oldValue);
      if (newValue) {
        await this.onLogin();
      }
    },
  },

  methods: {
    navigate() {
      const next = this.$route.query?.next;
      if (next) {
        this.$router.push(next);
      } else if (this.$store.getters.isAdmin) {
        this.$router.push({ name: "AdminHome" });
      } else {
        this.$router.push({ name: "Home" });
      }
    },
    async login() {
      this.error = false;
      try {
        await this.$store.dispatch("login", this.credentials);
      } catch (e) {
        this.error = true;
        console.debug("Login error:", e);
      }
    },
    async onLogin() {
      // Navigate
      this.navigate();
    },

    playIntroductionVideo() {
      const playArrow = document.getElementsByClassName(
        "play-btn-arrow-introduction"
      )[0];

      if (this.isPlaying == false && this.$session.get("firstTime", true)) {
        playArrow.classList.remove("fa-play");
        playArrow.classList.add("fa-pause");

        this.$refs.introductionVideo.play();
        this.isPlaying = true;
      } else {
        this.$refs.introductionVideo.pause();
        this.isPlaying = false;

        playArrow.classList.remove("fa-pause");

        playArrow.classList.add("fa-play");
      }
    },

    playWelcomeVideo() {
      const playArrow = document.getElementsByClassName("play-btn-arrow")[0];

      if (this.isPlaying == false) {
        playArrow.classList.remove("fa-play");
        playArrow.classList.add("fa-pause");

        this.$refs.welcomeVideo.play();
        this.isPlaying = true;
      } else {
        this.$refs.welcomeVideo.pause();
        this.isPlaying = false;

        playArrow.classList.remove("fa-pause");

        playArrow.classList.add("fa-play");
      }
    },

    toggleVideoOrCountDown() {
      var currentDate = new Date();
      var currentDateAsIso = currentDate.toISOString();

      if (this.testTime <= currentDateAsIso) {
        this.hideCountDown = true;
      }
    },
  },
};
</script>

<style scoped>
/*.main {
  background-image: url("https://vod.eventcdn.net/pandora/smc2021/Information_Bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media screen and (min-width: 1020px) {
  .main {
    background-image: url("https://vod.eventcdn.net/pandora/smc2021/Information_Bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    backface-visibility: hidden;
  }
}*/

.v-application.livehouse-app .countdown-value {
  font-size: 5rem !important;
  color: var(--v-primary-base) !important;
  line-height: 5rem !important;
  font-weight: 700 !important;
}

@media screen and (max-width: 992px) {
  .v-application.livehouse-app .countdown-value {
    font-size: 3rem !important;
    line-height: 3.5rem !important;
  }
}

.countdown-with-pink-text {
  color: var(--pink) !important;
  font: var(--unnamed-font-style-normal) normal bold 80px/60px
    var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal bold 80px/60px Pan Text;
}

.countdown-headline {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 22px/32px
    var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal normal 22px/32px Pan Text;
}

.unit {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-18) /
    var(--unnamed-line-spacing-22) var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--darker-grey);
  text-align: center;
  font: normal normal normal 18px/22px Pan Text;
}

.countdown-container {
  margin-top: 10.5rem;
}
</style>
